.SwapCompleted {
  font-family: "Montserrat", sans-serif; }
  .SwapCompleted_top {
    width: 100%; }
    .SwapCompleted_top h4 {
      text-align: center;
      color: #3D4767;
      font-weight: 700;
      font-size: 14px; }
    .SwapCompleted_top h1 {
      text-align: center;
      font-size: 36px;
      color: #1D1E21; }
    .SwapCompleted_top h5 {
      color: #747E8D;
      text-align: center; }
  .SwapCompleted_transactionTitle {
    font-weight: bold;
    color: #1D1E21; }
    .SwapCompleted_transactionTitle::hover {
      cursor: pointer;
      text-decoration: underline; }
  .SwapCompleted_link {
    padding-left: 40px;
    z-index: 3; }
    .SwapCompleted_link:hover {
      cursor: pointer; }
  .SwapCompleted_receivedAmount {
    font-size: 30px;
    font-weight: 300; }
  .SwapCompleted_sentAmount {
    font-size: 28px;
    text-align: center;
    font-weight: 300; }
  .SwapCompleted_rateAmount {
    font-weight: 600;
    font-size: 24px;
    text-align: center; }
  .SwapCompleted_confs {
    color: #747E8D;
    padding-left: 5px; }
  .SwapCompleted_confText {
    font-size: 12px;
    padding-left: 2px; }
  .SwapCompleted_bottom {
    z-index: 3;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between; }
    .SwapCompleted_bottom h4 {
      font-size: 14px; }
  .SwapCompleted_bottomButton {
    display: flex;
    justify-content: center; }
  .SwapCompleted_shareSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }
    .SwapCompleted_shareSection h3 {
      font-size: 14px;
      font-weight: bold; }
    .SwapCompleted_shareSection img:hover {
      cursor: pointer; }
  .SwapCompleted_right {
    padding-right: 10px;
    width: 100%; }
  .SwapCompleted_left {
    padding-left: 30px;
    width: 100%; }
  .SwapCompleted_transactionHash {
    color: #9D4DFA;
    width: 100%;
    padding-left: 4px; }
  .SwapCompleted_shareLeft {
    display: flex;
    flex-flow: row nowrap; }
  .SwapCompleted_shareRight {
    display: flex;
    flex-flow: row nowrap; }
  .SwapCompleted_confetti-wrapper {
    height: 500px;
    position: absolute;
    width: 100%;
    z-index: 1; }
  .SwapCompleted [class|="confetti"] {
    position: absolute; }
  .SwapCompleted .confetti-0 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 47%;
    opacity: 0.81148;
    transform: rotate(131.65132deg);
    animation: drop-0 4.60079s 0.58728s infinite; }

@keyframes drop-0 {
  0% {
    top: 80px;
    left: 49%; } }
  .SwapCompleted .confetti-1 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 64%;
    opacity: 0.92895;
    transform: rotate(303.23981deg);
    animation: drop-1 4.3142s 0.65078s infinite; }

@keyframes drop-1 {
  0% {
    top: 80px;
    left: 66%; } }
  .SwapCompleted .confetti-2 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 46%;
    opacity: 1.28008;
    transform: rotate(340.85076deg);
    animation: drop-2 4.60251s 0.1118s infinite; }

@keyframes drop-2 {
  0% {
    top: 80px;
    left: 51%; } }
  .SwapCompleted .confetti-3 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 24%;
    opacity: 1.03362;
    transform: rotate(236.65132deg);
    animation: drop-3 4.06669s 0.05324s infinite; }

@keyframes drop-3 {
  0% {
    top: 80px;
    left: 25%; } }
  .SwapCompleted .confetti-4 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 94%;
    opacity: 0.65722;
    transform: rotate(341.39832deg);
    animation: drop-4 4.29866s 0.0933s infinite; }

@keyframes drop-4 {
  0% {
    top: 80px;
    left: 98%; } }
  .SwapCompleted .confetti-5 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 60%;
    opacity: 0.58215;
    transform: rotate(152.90399deg);
    animation: drop-5 4.48908s 0.50035s infinite; }

@keyframes drop-5 {
  0% {
    top: 80px;
    left: 62%; } }
  .SwapCompleted .confetti-6 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 3%;
    opacity: 0.48081;
    transform: rotate(45.00915deg);
    animation: drop-6 4.5047s 0.40668s infinite; }

@keyframes drop-6 {
  0% {
    top: 80px;
    left: 5%; } }
  .SwapCompleted .confetti-7 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 73%;
    opacity: 0.70232;
    transform: rotate(139.55935deg);
    animation: drop-7 4.22678s 0.00401s infinite; }

@keyframes drop-7 {
  0% {
    top: 80px;
    left: 74%; } }
  .SwapCompleted .confetti-8 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 33%;
    opacity: 1.2954;
    transform: rotate(151.34794deg);
    animation: drop-8 4.01268s 0.80636s infinite; }

@keyframes drop-8 {
  0% {
    top: 80px;
    left: 37%; } }
  .SwapCompleted .confetti-9 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 77%;
    opacity: 0.7239;
    transform: rotate(58.45053deg);
    animation: drop-9 4.49946s 0.38879s infinite; }

@keyframes drop-9 {
  0% {
    top: 80px;
    left: 80%; } }
  .SwapCompleted .confetti-10 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 77%;
    opacity: 1.2052;
    transform: rotate(347.71236deg);
    animation: drop-10 4.11496s 0.42587s infinite; }

@keyframes drop-10 {
  0% {
    top: 80px;
    left: 80%; } }
  .SwapCompleted .confetti-11 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 72%;
    opacity: 0.48008;
    transform: rotate(89.60432deg);
    animation: drop-11 4.75838s 0.97098s infinite; }

@keyframes drop-11 {
  0% {
    top: 80px;
    left: 75%; } }
  .SwapCompleted .confetti-12 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 9%;
    opacity: 0.57059;
    transform: rotate(227.52952deg);
    animation: drop-12 4.34463s 0.9142s infinite; }

@keyframes drop-12 {
  0% {
    top: 80px;
    left: 14%; } }
  .SwapCompleted .confetti-13 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 64%;
    opacity: 1.19867;
    transform: rotate(204.63223deg);
    animation: drop-13 4.21777s 0.64132s infinite; }

@keyframes drop-13 {
  0% {
    top: 80px;
    left: 66%; } }
  .SwapCompleted .confetti-14 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 11%;
    opacity: 0.61553;
    transform: rotate(248.01727deg);
    animation: drop-14 4.94729s 0.17555s infinite; }

@keyframes drop-14 {
  0% {
    top: 80px;
    left: 12%; } }
  .SwapCompleted .confetti-15 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 93%;
    opacity: 0.58662;
    transform: rotate(215.83743deg);
    animation: drop-15 4.65981s 0.47072s infinite; }

@keyframes drop-15 {
  0% {
    top: 80px;
    left: 97%; } }
  .SwapCompleted .confetti-16 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 69%;
    opacity: 0.97189;
    transform: rotate(240.28265deg);
    animation: drop-16 4.10167s 0.5342s infinite; }

@keyframes drop-16 {
  0% {
    top: 80px;
    left: 73%; } }
  .SwapCompleted .confetti-17 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 29%;
    opacity: 0.39202;
    transform: rotate(145.49326deg);
    animation: drop-17 4.05918s 0.53298s infinite; }

@keyframes drop-17 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-18 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 62%;
    opacity: 1.23381;
    transform: rotate(62.47183deg);
    animation: drop-18 4.04245s 0.4756s infinite; }

@keyframes drop-18 {
  0% {
    top: 80px;
    left: 65%; } }
  .SwapCompleted .confetti-19 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 20%;
    opacity: 0.99912;
    transform: rotate(307.71776deg);
    animation: drop-19 4.75879s 0.84688s infinite; }

@keyframes drop-19 {
  0% {
    top: 80px;
    left: 25%; } }
  .SwapCompleted .confetti-20 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 42%;
    opacity: 0.75652;
    transform: rotate(154.79746deg);
    animation: drop-20 4.40978s 0.28206s infinite; }

@keyframes drop-20 {
  0% {
    top: 80px;
    left: 46%; } }
  .SwapCompleted .confetti-21 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 52%;
    opacity: 0.58894;
    transform: rotate(283.43875deg);
    animation: drop-21 4.53529s 0.0228s infinite; }

@keyframes drop-21 {
  0% {
    top: 80px;
    left: 57%; } }
  .SwapCompleted .confetti-22 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 35%;
    opacity: 0.50678;
    transform: rotate(196.18453deg);
    animation: drop-22 4.98968s 0.26011s infinite; }

@keyframes drop-22 {
  0% {
    top: 80px;
    left: 40%; } }
  .SwapCompleted .confetti-23 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 9%;
    opacity: 1.20688;
    transform: rotate(6.73566deg);
    animation: drop-23 4.83548s 0.8748s infinite; }

@keyframes drop-23 {
  0% {
    top: 80px;
    left: 13%; } }
  .SwapCompleted .confetti-24 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 65%;
    opacity: 0.8776;
    transform: rotate(248.56499deg);
    animation: drop-24 4.2936s 0.6894s infinite; }

@keyframes drop-24 {
  0% {
    top: 80px;
    left: 70%; } }
  .SwapCompleted .confetti-25 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 50%;
    opacity: 0.94896;
    transform: rotate(95.24077deg);
    animation: drop-25 4.53394s 0.53444s infinite; }

@keyframes drop-25 {
  0% {
    top: 80px;
    left: 53%; } }
  .SwapCompleted .confetti-26 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 29%;
    opacity: 1.18219;
    transform: rotate(36.26279deg);
    animation: drop-26 4.57987s 0.88304s infinite; }

@keyframes drop-26 {
  0% {
    top: 80px;
    left: 30%; } }
  .SwapCompleted .confetti-27 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 78%;
    opacity: 1.07237;
    transform: rotate(297.35307deg);
    animation: drop-27 4.38426s 0.30015s infinite; }

@keyframes drop-27 {
  0% {
    top: 80px;
    left: 81%; } }
  .SwapCompleted .confetti-28 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 6%;
    opacity: 0.65766;
    transform: rotate(213.73678deg);
    animation: drop-28 4.31116s 0.70021s infinite; }

@keyframes drop-28 {
  0% {
    top: 80px;
    left: 9%; } }
  .SwapCompleted .confetti-29 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 68%;
    opacity: 1.14292;
    transform: rotate(76.23439deg);
    animation: drop-29 4.2784s 0.42975s infinite; }

@keyframes drop-29 {
  0% {
    top: 80px;
    left: 73%; } }
  .SwapCompleted .confetti-30 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 46%;
    opacity: 0.32583;
    transform: rotate(49.80382deg);
    animation: drop-30 4.30893s 0.9727s infinite; }

@keyframes drop-30 {
  0% {
    top: 80px;
    left: 47%; } }
  .SwapCompleted .confetti-31 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 28%;
    opacity: 0.52939;
    transform: rotate(146.04991deg);
    animation: drop-31 4.05719s 0.96561s infinite; }

@keyframes drop-31 {
  0% {
    top: 80px;
    left: 30%; } }
  .SwapCompleted .confetti-32 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 58%;
    opacity: 0.60002;
    transform: rotate(61.97688deg);
    animation: drop-32 4.04696s 0.92837s infinite; }

@keyframes drop-32 {
  0% {
    top: 80px;
    left: 61%; } }
  .SwapCompleted .confetti-33 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 54%;
    opacity: 0.80199;
    transform: rotate(115.13765deg);
    animation: drop-33 4.9458s 0.97308s infinite; }

@keyframes drop-33 {
  0% {
    top: 80px;
    left: 59%; } }
  .SwapCompleted .confetti-34 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 69%;
    opacity: 0.37496;
    transform: rotate(77.14409deg);
    animation: drop-34 4.75487s 0.07835s infinite; }

@keyframes drop-34 {
  0% {
    top: 80px;
    left: 71%; } }
  .SwapCompleted .confetti-35 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 20%;
    opacity: 0.88318;
    transform: rotate(51.13739deg);
    animation: drop-35 4.93979s 0.77511s infinite; }

@keyframes drop-35 {
  0% {
    top: 80px;
    left: 25%; } }
  .SwapCompleted .confetti-36 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 30%;
    opacity: 0.88977;
    transform: rotate(4.24125deg);
    animation: drop-36 4.72322s 0.07577s infinite; }

@keyframes drop-36 {
  0% {
    top: 80px;
    left: 35%; } }
  .SwapCompleted .confetti-37 {
    width: 6px;
    height: 2.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 26%;
    opacity: 0.64982;
    transform: rotate(125.11636deg);
    animation: drop-37 4.06675s 0.80614s infinite; }

@keyframes drop-37 {
  0% {
    top: 80px;
    left: 28%; } }
  .SwapCompleted .confetti-38 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 15%;
    opacity: 1.11596;
    transform: rotate(245.86437deg);
    animation: drop-38 4.47747s 0.3413s infinite; }

@keyframes drop-38 {
  0% {
    top: 80px;
    left: 18%; } }
  .SwapCompleted .confetti-39 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 58%;
    opacity: 1.09289;
    transform: rotate(272.9025deg);
    animation: drop-39 4.83482s 0.52159s infinite; }

@keyframes drop-39 {
  0% {
    top: 80px;
    left: 59%; } }
  .SwapCompleted .confetti-40 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 74%;
    opacity: 0.47666;
    transform: rotate(27.5449deg);
    animation: drop-40 4.55634s 0.94956s infinite; }

@keyframes drop-40 {
  0% {
    top: 80px;
    left: 77%; } }
  .SwapCompleted .confetti-41 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 62%;
    opacity: 0.59746;
    transform: rotate(188.85615deg);
    animation: drop-41 4.8478s 0.89471s infinite; }

@keyframes drop-41 {
  0% {
    top: 80px;
    left: 67%; } }
  .SwapCompleted .confetti-42 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 41%;
    opacity: 0.58562;
    transform: rotate(83.27799deg);
    animation: drop-42 4.46598s 0.83415s infinite; }

@keyframes drop-42 {
  0% {
    top: 80px;
    left: 43%; } }
  .SwapCompleted .confetti-43 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 93%;
    opacity: 0.74789;
    transform: rotate(27.31622deg);
    animation: drop-43 4.22415s 0.89733s infinite; }

@keyframes drop-43 {
  0% {
    top: 80px;
    left: 94%; } }
  .SwapCompleted .confetti-44 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 52%;
    opacity: 0.31367;
    transform: rotate(34.01148deg);
    animation: drop-44 4.6348s 0.55967s infinite; }

@keyframes drop-44 {
  0% {
    top: 80px;
    left: 54%; } }
  .SwapCompleted .confetti-45 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 32%;
    opacity: 0.94821;
    transform: rotate(53.34084deg);
    animation: drop-45 4.54915s 0.91438s infinite; }

@keyframes drop-45 {
  0% {
    top: 80px;
    left: 37%; } }
  .SwapCompleted .confetti-46 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 8%;
    opacity: 0.30105;
    transform: rotate(333.91347deg);
    animation: drop-46 4.22908s 0.86599s infinite; }

@keyframes drop-46 {
  0% {
    top: 80px;
    left: 13%; } }
  .SwapCompleted .confetti-47 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 41%;
    opacity: 0.87251;
    transform: rotate(193.9976deg);
    animation: drop-47 4.14251s 0.78327s infinite; }

@keyframes drop-47 {
  0% {
    top: 80px;
    left: 42%; } }
  .SwapCompleted .confetti-48 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 17%;
    opacity: 0.79156;
    transform: rotate(4.3036deg);
    animation: drop-48 4.00373s 0.65549s infinite; }

@keyframes drop-48 {
  0% {
    top: 80px;
    left: 22%; } }
  .SwapCompleted .confetti-49 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 73%;
    opacity: 0.90685;
    transform: rotate(327.85831deg);
    animation: drop-49 4.45881s 0.20505s infinite; }

@keyframes drop-49 {
  0% {
    top: 80px;
    left: 74%; } }
  .SwapCompleted .confetti-50 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 68%;
    opacity: 0.63384;
    transform: rotate(338.6787deg);
    animation: drop-50 4.91342s 0.10482s infinite; }

@keyframes drop-50 {
  0% {
    top: 80px;
    left: 72%; } }
  .SwapCompleted .confetti-51 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 33%;
    opacity: 0.96965;
    transform: rotate(111.82378deg);
    animation: drop-51 4.9374s 0.63896s infinite; }

@keyframes drop-51 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-52 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 24%;
    opacity: 0.63634;
    transform: rotate(6.27733deg);
    animation: drop-52 4.10723s 0.10311s infinite; }

@keyframes drop-52 {
  0% {
    top: 80px;
    left: 29%; } }
  .SwapCompleted .confetti-53 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 48%;
    opacity: 1.21894;
    transform: rotate(296.4347deg);
    animation: drop-53 4.16147s 0.69747s infinite; }

@keyframes drop-53 {
  0% {
    top: 80px;
    left: 51%; } }
  .SwapCompleted .confetti-54 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 21%;
    opacity: 1.19882;
    transform: rotate(307.4663deg);
    animation: drop-54 4.96879s 0.81997s infinite; }

@keyframes drop-54 {
  0% {
    top: 80px;
    left: 26%; } }
  .SwapCompleted .confetti-55 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 78%;
    opacity: 1.01993;
    transform: rotate(22.53107deg);
    animation: drop-55 4.89055s 0.28831s infinite; }

@keyframes drop-55 {
  0% {
    top: 80px;
    left: 80%; } }
  .SwapCompleted .confetti-56 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 85%;
    opacity: 1.27029;
    transform: rotate(90.39345deg);
    animation: drop-56 4.45865s 0.8786s infinite; }

@keyframes drop-56 {
  0% {
    top: 80px;
    left: 87%; } }
  .SwapCompleted .confetti-57 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 75%;
    opacity: 0.52413;
    transform: rotate(154.85256deg);
    animation: drop-57 4.89712s 0.96433s infinite; }

@keyframes drop-57 {
  0% {
    top: 80px;
    left: 79%; } }
  .SwapCompleted .confetti-58 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 92%;
    opacity: 0.69753;
    transform: rotate(150.19444deg);
    animation: drop-58 4.12137s 0.1437s infinite; }

@keyframes drop-58 {
  0% {
    top: 80px;
    left: 95%; } }
  .SwapCompleted .confetti-59 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 18%;
    opacity: 0.47655;
    transform: rotate(70.46329deg);
    animation: drop-59 4.71505s 0.40346s infinite; }

@keyframes drop-59 {
  0% {
    top: 80px;
    left: 20%; } }
  .SwapCompleted .confetti-60 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 49%;
    opacity: 0.771;
    transform: rotate(239.8959deg);
    animation: drop-60 4.68088s 0.3648s infinite; }

@keyframes drop-60 {
  0% {
    top: 80px;
    left: 52%; } }
  .SwapCompleted .confetti-61 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 95%;
    opacity: 0.59818;
    transform: rotate(270.15826deg);
    animation: drop-61 4.78689s 0.29415s infinite; }

@keyframes drop-61 {
  0% {
    top: 80px;
    left: 97%; } }
  .SwapCompleted .confetti-62 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 38%;
    opacity: 0.61261;
    transform: rotate(278.9131deg);
    animation: drop-62 4.00278s 0.50121s infinite; }

@keyframes drop-62 {
  0% {
    top: 80px;
    left: 40%; } }
  .SwapCompleted .confetti-63 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 89%;
    opacity: 0.4181;
    transform: rotate(302.13466deg);
    animation: drop-63 4.37843s 0.26313s infinite; }

@keyframes drop-63 {
  0% {
    top: 80px;
    left: 91%; } }
  .SwapCompleted .confetti-64 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 54%;
    opacity: 0.40004;
    transform: rotate(75.69357deg);
    animation: drop-64 4.14027s 0.1874s infinite; }

@keyframes drop-64 {
  0% {
    top: 80px;
    left: 58%; } }
  .SwapCompleted .confetti-65 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 60%;
    opacity: 0.33517;
    transform: rotate(135.97307deg);
    animation: drop-65 4.27051s 0.84983s infinite; }

@keyframes drop-65 {
  0% {
    top: 80px;
    left: 65%; } }
  .SwapCompleted .confetti-66 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 43%;
    opacity: 0.39997;
    transform: rotate(166.92531deg);
    animation: drop-66 4.32234s 0.31039s infinite; }

@keyframes drop-66 {
  0% {
    top: 80px;
    left: 46%; } }
  .SwapCompleted .confetti-67 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 14%;
    opacity: 0.79493;
    transform: rotate(264.75532deg);
    animation: drop-67 4.37106s 0.99822s infinite; }

@keyframes drop-67 {
  0% {
    top: 80px;
    left: 16%; } }
  .SwapCompleted .confetti-68 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 75%;
    opacity: 1.26764;
    transform: rotate(162.72106deg);
    animation: drop-68 4.64624s 0.57739s infinite; }

@keyframes drop-68 {
  0% {
    top: 80px;
    left: 78%; } }
  .SwapCompleted .confetti-69 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 53%;
    opacity: 1.16056;
    transform: rotate(298.18318deg);
    animation: drop-69 4.79037s 0.68455s infinite; }

@keyframes drop-69 {
  0% {
    top: 80px;
    left: 56%; } }
  .SwapCompleted .confetti-70 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 58%;
    opacity: 0.34394;
    transform: rotate(122.65512deg);
    animation: drop-70 4.92635s 0.39965s infinite; }

@keyframes drop-70 {
  0% {
    top: 80px;
    left: 60%; } }
  .SwapCompleted .confetti-71 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 62%;
    opacity: 0.35715;
    transform: rotate(18.88603deg);
    animation: drop-71 4.289s 0.13868s infinite; }

@keyframes drop-71 {
  0% {
    top: 80px;
    left: 67%; } }
  .SwapCompleted .confetti-72 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 84%;
    opacity: 1.049;
    transform: rotate(334.83978deg);
    animation: drop-72 4.1832s 0.86735s infinite; }

@keyframes drop-72 {
  0% {
    top: 80px;
    left: 87%; } }
  .SwapCompleted .confetti-73 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 60%;
    opacity: 0.4889;
    transform: rotate(22.48688deg);
    animation: drop-73 4.97955s 0.75132s infinite; }

@keyframes drop-73 {
  0% {
    top: 80px;
    left: 61%; } }
  .SwapCompleted .confetti-74 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 82%;
    opacity: 0.71056;
    transform: rotate(291.84808deg);
    animation: drop-74 4.62924s 0.00342s infinite; }

@keyframes drop-74 {
  0% {
    top: 80px;
    left: 83%; } }
  .SwapCompleted .confetti-75 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 48%;
    opacity: 0.60443;
    transform: rotate(340.33887deg);
    animation: drop-75 4.23011s 0.31816s infinite; }

@keyframes drop-75 {
  0% {
    top: 80px;
    left: 53%; } }
  .SwapCompleted .confetti-76 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 22%;
    opacity: 0.31823;
    transform: rotate(333.9503deg);
    animation: drop-76 4.38999s 0.43869s infinite; }

@keyframes drop-76 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-77 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 14%;
    opacity: 0.91748;
    transform: rotate(208.90295deg);
    animation: drop-77 4.67889s 0.64678s infinite; }

@keyframes drop-77 {
  0% {
    top: 80px;
    left: 18%; } }
  .SwapCompleted .confetti-78 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 14%;
    opacity: 1.21406;
    transform: rotate(208.01867deg);
    animation: drop-78 4.34697s 0.1385s infinite; }

@keyframes drop-78 {
  0% {
    top: 80px;
    left: 15%; } }
  .SwapCompleted .confetti-79 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 49%;
    opacity: 0.76318;
    transform: rotate(277.00899deg);
    animation: drop-79 4.94652s 0.45799s infinite; }

@keyframes drop-79 {
  0% {
    top: 80px;
    left: 51%; } }
  .SwapCompleted .confetti-80 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 74%;
    opacity: 0.85931;
    transform: rotate(47.14569deg);
    animation: drop-80 4.78292s 0.56315s infinite; }

@keyframes drop-80 {
  0% {
    top: 80px;
    left: 79%; } }
  .SwapCompleted .confetti-81 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 76%;
    opacity: 0.38822;
    transform: rotate(7.61173deg);
    animation: drop-81 4.93878s 0.29479s infinite; }

@keyframes drop-81 {
  0% {
    top: 80px;
    left: 81%; } }
  .SwapCompleted .confetti-82 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 22%;
    opacity: 1.13611;
    transform: rotate(287.32363deg);
    animation: drop-82 4.15803s 0.61836s infinite; }

@keyframes drop-82 {
  0% {
    top: 80px;
    left: 23%; } }
  .SwapCompleted .confetti-83 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 35%;
    opacity: 0.52927;
    transform: rotate(172.90576deg);
    animation: drop-83 4.98532s 0.69532s infinite; }

@keyframes drop-83 {
  0% {
    top: 80px;
    left: 36%; } }
  .SwapCompleted .confetti-84 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 78%;
    opacity: 1.21615;
    transform: rotate(232.19541deg);
    animation: drop-84 4.49921s 0.59132s infinite; }

@keyframes drop-84 {
  0% {
    top: 80px;
    left: 80%; } }
  .SwapCompleted .confetti-85 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 93%;
    opacity: 0.6709;
    transform: rotate(294.72053deg);
    animation: drop-85 4.09735s 0.20247s infinite; }

@keyframes drop-85 {
  0% {
    top: 80px;
    left: 97%; } }
  .SwapCompleted .confetti-86 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 69%;
    opacity: 1.24761;
    transform: rotate(319.50595deg);
    animation: drop-86 4.01755s 0.45139s infinite; }

@keyframes drop-86 {
  0% {
    top: 80px;
    left: 73%; } }
  .SwapCompleted .confetti-87 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 34%;
    opacity: 0.98318;
    transform: rotate(37.72247deg);
    animation: drop-87 4.28757s 0.23343s infinite; }

@keyframes drop-87 {
  0% {
    top: 80px;
    left: 36%; } }
  .SwapCompleted .confetti-88 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 82%;
    opacity: 0.54779;
    transform: rotate(181.81349deg);
    animation: drop-88 4.78258s 0.66569s infinite; }

@keyframes drop-88 {
  0% {
    top: 80px;
    left: 86%; } }
  .SwapCompleted .confetti-89 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 72%;
    opacity: 0.70581;
    transform: rotate(91.25324deg);
    animation: drop-89 4.17342s 0.27208s infinite; }

@keyframes drop-89 {
  0% {
    top: 80px;
    left: 77%; } }
  .SwapCompleted .confetti-90 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 44%;
    opacity: 1.18917;
    transform: rotate(115.83634deg);
    animation: drop-90 4.58938s 0.78603s infinite; }

@keyframes drop-90 {
  0% {
    top: 80px;
    left: 48%; } }
  .SwapCompleted .confetti-91 {
    width: 6px;
    height: 2.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 69%;
    opacity: 0.62111;
    transform: rotate(257.1481deg);
    animation: drop-91 4.14071s 0.25429s infinite; }

@keyframes drop-91 {
  0% {
    top: 80px;
    left: 73%; } }
  .SwapCompleted .confetti-92 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 19%;
    opacity: 1.20753;
    transform: rotate(274.28115deg);
    animation: drop-92 4.04395s 0.33993s infinite; }

@keyframes drop-92 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-93 {
    width: 6px;
    height: 2.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 87%;
    opacity: 0.34165;
    transform: rotate(98.76175deg);
    animation: drop-93 4.76323s 0.35047s infinite; }

@keyframes drop-93 {
  0% {
    top: 80px;
    left: 92%; } }
  .SwapCompleted .confetti-94 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 88%;
    opacity: 0.84415;
    transform: rotate(339.99006deg);
    animation: drop-94 4.851s 0.67404s infinite; }

@keyframes drop-94 {
  0% {
    top: 80px;
    left: 91%; } }
  .SwapCompleted .confetti-95 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 50%;
    opacity: 0.79847;
    transform: rotate(280.61789deg);
    animation: drop-95 4.7153s 0.46758s infinite; }

@keyframes drop-95 {
  0% {
    top: 80px;
    left: 52%; } }
  .SwapCompleted .confetti-96 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 80%;
    opacity: 0.40417;
    transform: rotate(355.30253deg);
    animation: drop-96 4.40063s 0.52952s infinite; }

@keyframes drop-96 {
  0% {
    top: 80px;
    left: 82%; } }
  .SwapCompleted .confetti-97 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 80%;
    opacity: 1.03598;
    transform: rotate(333.89456deg);
    animation: drop-97 4.69866s 0.38215s infinite; }

@keyframes drop-97 {
  0% {
    top: 80px;
    left: 85%; } }
  .SwapCompleted .confetti-98 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 42%;
    opacity: 1.14308;
    transform: rotate(83.294deg);
    animation: drop-98 4.38349s 0.85972s infinite; }

@keyframes drop-98 {
  0% {
    top: 80px;
    left: 46%; } }
  .SwapCompleted .confetti-99 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 21%;
    opacity: 1.18534;
    transform: rotate(155.76223deg);
    animation: drop-99 4.45719s 0.58896s infinite; }

@keyframes drop-99 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-100 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 33%;
    opacity: 1.22136;
    transform: rotate(326.32083deg);
    animation: drop-100 4.49138s 0.90109s infinite; }

@keyframes drop-100 {
  0% {
    top: 80px;
    left: 38%; } }
  .SwapCompleted .confetti-101 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 4%;
    opacity: 0.99886;
    transform: rotate(110.14999deg);
    animation: drop-101 4.16049s 0.48912s infinite; }

@keyframes drop-101 {
  0% {
    top: 80px;
    left: 7%; } }
  .SwapCompleted .confetti-102 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 46%;
    opacity: 1.10733;
    transform: rotate(50.36805deg);
    animation: drop-102 4.47987s 0.12663s infinite; }

@keyframes drop-102 {
  0% {
    top: 80px;
    left: 50%; } }
  .SwapCompleted .confetti-103 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 36%;
    opacity: 0.53686;
    transform: rotate(262.55369deg);
    animation: drop-103 4.91791s 0.18014s infinite; }

@keyframes drop-103 {
  0% {
    top: 80px;
    left: 38%; } }
  .SwapCompleted .confetti-104 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 20%;
    opacity: 0.6523;
    transform: rotate(321.92331deg);
    animation: drop-104 4.65322s 0.67767s infinite; }

@keyframes drop-104 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-105 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 31%;
    opacity: 0.5422;
    transform: rotate(170.89815deg);
    animation: drop-105 4.6806s 0.19066s infinite; }

@keyframes drop-105 {
  0% {
    top: 80px;
    left: 35%; } }
  .SwapCompleted .confetti-106 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 46%;
    opacity: 1.00182;
    transform: rotate(99.47777deg);
    animation: drop-106 4.27306s 0.71554s infinite; }

@keyframes drop-106 {
  0% {
    top: 80px;
    left: 51%; } }
  .SwapCompleted .confetti-107 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 28%;
    opacity: 1.05006;
    transform: rotate(345.32505deg);
    animation: drop-107 4.41243s 0.94105s infinite; }

@keyframes drop-107 {
  0% {
    top: 80px;
    left: 32%; } }
  .SwapCompleted .confetti-108 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 61%;
    opacity: 1.05801;
    transform: rotate(12.28741deg);
    animation: drop-108 4.58562s 0.04251s infinite; }

@keyframes drop-108 {
  0% {
    top: 80px;
    left: 64%; } }
  .SwapCompleted .confetti-109 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 74%;
    opacity: 0.79014;
    transform: rotate(227.81507deg);
    animation: drop-109 4.74245s 0.43446s infinite; }

@keyframes drop-109 {
  0% {
    top: 80px;
    left: 78%; } }
  .SwapCompleted .confetti-110 {
    width: 6px;
    height: 2.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 83%;
    opacity: 0.51007;
    transform: rotate(279.24472deg);
    animation: drop-110 4.5083s 0.43162s infinite; }

@keyframes drop-110 {
  0% {
    top: 80px;
    left: 87%; } }
  .SwapCompleted .confetti-111 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 59%;
    opacity: 0.31063;
    transform: rotate(18.40117deg);
    animation: drop-111 4.70741s 0.3282s infinite; }

@keyframes drop-111 {
  0% {
    top: 80px;
    left: 61%; } }
  .SwapCompleted .confetti-112 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 78%;
    opacity: 0.43546;
    transform: rotate(326.89115deg);
    animation: drop-112 4.9916s 0.24014s infinite; }

@keyframes drop-112 {
  0% {
    top: 80px;
    left: 81%; } }
  .SwapCompleted .confetti-113 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 34%;
    opacity: 0.77267;
    transform: rotate(239.05878deg);
    animation: drop-113 4.91409s 0.18448s infinite; }

@keyframes drop-113 {
  0% {
    top: 80px;
    left: 37%; } }
  .SwapCompleted .confetti-114 {
    width: 2px;
    height: 0.8px;
    background-color: #F12274;
    top: 690px;
    left: 55%;
    opacity: 1.06563;
    transform: rotate(286.09052deg);
    animation: drop-114 4.91139s 0.59407s infinite; }

@keyframes drop-114 {
  0% {
    top: 80px;
    left: 59%; } }
  .SwapCompleted .confetti-115 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 46%;
    opacity: 0.40279;
    transform: rotate(146.67357deg);
    animation: drop-115 4.27771s 0.9044s infinite; }

@keyframes drop-115 {
  0% {
    top: 80px;
    left: 50%; } }
  .SwapCompleted .confetti-116 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 14%;
    opacity: 0.32519;
    transform: rotate(219.44113deg);
    animation: drop-116 4.02471s 0.49842s infinite; }

@keyframes drop-116 {
  0% {
    top: 80px;
    left: 18%; } }
  .SwapCompleted .confetti-117 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 10%;
    opacity: 0.82724;
    transform: rotate(137.81922deg);
    animation: drop-117 4.85544s 0.14774s infinite; }

@keyframes drop-117 {
  0% {
    top: 80px;
    left: 13%; } }
  .SwapCompleted .confetti-118 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 68%;
    opacity: 1.29895;
    transform: rotate(161.12344deg);
    animation: drop-118 4.86515s 0.69363s infinite; }

@keyframes drop-118 {
  0% {
    top: 80px;
    left: 69%; } }
  .SwapCompleted .confetti-119 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 18%;
    opacity: 0.72428;
    transform: rotate(8.97979deg);
    animation: drop-119 4.62651s 0.41431s infinite; }

@keyframes drop-119 {
  0% {
    top: 80px;
    left: 20%; } }
  .SwapCompleted .confetti-120 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 59%;
    opacity: 1.18852;
    transform: rotate(254.17549deg);
    animation: drop-120 4.58757s 0.0434s infinite; }

@keyframes drop-120 {
  0% {
    top: 80px;
    left: 60%; } }
  .SwapCompleted .confetti-121 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 41%;
    opacity: 0.34206;
    transform: rotate(284.35639deg);
    animation: drop-121 4.77329s 0.59938s infinite; }

@keyframes drop-121 {
  0% {
    top: 80px;
    left: 43%; } }
  .SwapCompleted .confetti-122 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 14%;
    opacity: 0.6427;
    transform: rotate(113.87565deg);
    animation: drop-122 4.6683s 0.26657s infinite; }

@keyframes drop-122 {
  0% {
    top: 80px;
    left: 15%; } }
  .SwapCompleted .confetti-123 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 91%;
    opacity: 0.67266;
    transform: rotate(56.28109deg);
    animation: drop-123 4.99871s 0.48551s infinite; }

@keyframes drop-123 {
  0% {
    top: 80px;
    left: 94%; } }
  .SwapCompleted .confetti-124 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 89%;
    opacity: 0.81814;
    transform: rotate(96.02505deg);
    animation: drop-124 4.3078s 0.7181s infinite; }

@keyframes drop-124 {
  0% {
    top: 80px;
    left: 91%; } }
  .SwapCompleted .confetti-125 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 8%;
    opacity: 0.44932;
    transform: rotate(275.98099deg);
    animation: drop-125 4.92709s 0.28147s infinite; }

@keyframes drop-125 {
  0% {
    top: 80px;
    left: 12%; } }
  .SwapCompleted .confetti-126 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 66%;
    opacity: 0.88235;
    transform: rotate(212.13735deg);
    animation: drop-126 4.75049s 0.68281s infinite; }

@keyframes drop-126 {
  0% {
    top: 80px;
    left: 67%; } }
  .SwapCompleted .confetti-127 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 3%;
    opacity: 0.50917;
    transform: rotate(174.81753deg);
    animation: drop-127 4.87295s 0.65447s infinite; }

@keyframes drop-127 {
  0% {
    top: 80px;
    left: 5%; } }
  .SwapCompleted .confetti-128 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 42%;
    opacity: 0.84844;
    transform: rotate(122.09132deg);
    animation: drop-128 4.95803s 0.30929s infinite; }

@keyframes drop-128 {
  0% {
    top: 80px;
    left: 47%; } }
  .SwapCompleted .confetti-129 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 9%;
    opacity: 0.40566;
    transform: rotate(339.43769deg);
    animation: drop-129 4.06317s 0.23578s infinite; }

@keyframes drop-129 {
  0% {
    top: 80px;
    left: 14%; } }
  .SwapCompleted .confetti-130 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 15%;
    opacity: 0.42194;
    transform: rotate(286.71248deg);
    animation: drop-130 4.30064s 0.45209s infinite; }

@keyframes drop-130 {
  0% {
    top: 80px;
    left: 17%; } }
  .SwapCompleted .confetti-131 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 77%;
    opacity: 0.58878;
    transform: rotate(143.42174deg);
    animation: drop-131 4.60683s 0.70072s infinite; }

@keyframes drop-131 {
  0% {
    top: 80px;
    left: 82%; } }
  .SwapCompleted .confetti-132 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 44%;
    opacity: 0.91135;
    transform: rotate(243.31438deg);
    animation: drop-132 4.30526s 0.48645s infinite; }

@keyframes drop-132 {
  0% {
    top: 80px;
    left: 46%; } }
  .SwapCompleted .confetti-133 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 20%;
    opacity: 0.51086;
    transform: rotate(16.12474deg);
    animation: drop-133 4.23979s 0.22565s infinite; }

@keyframes drop-133 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-134 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 46%;
    opacity: 1.04615;
    transform: rotate(31.03786deg);
    animation: drop-134 4.09014s 0.80452s infinite; }

@keyframes drop-134 {
  0% {
    top: 80px;
    left: 48%; } }
  .SwapCompleted .confetti-135 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 50%;
    opacity: 0.41263;
    transform: rotate(157.28932deg);
    animation: drop-135 4.04822s 0.50194s infinite; }

@keyframes drop-135 {
  0% {
    top: 80px;
    left: 52%; } }
  .SwapCompleted .confetti-136 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 31%;
    opacity: 0.79931;
    transform: rotate(283.0312deg);
    animation: drop-136 4.71196s 0.18769s infinite; }

@keyframes drop-136 {
  0% {
    top: 80px;
    left: 35%; } }
  .SwapCompleted .confetti-137 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 40%;
    opacity: 0.90295;
    transform: rotate(216.58696deg);
    animation: drop-137 4.61438s 0.93707s infinite; }

@keyframes drop-137 {
  0% {
    top: 80px;
    left: 43%; } }
  .SwapCompleted .confetti-138 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 65%;
    opacity: 0.39095;
    transform: rotate(354.75208deg);
    animation: drop-138 4.95324s 0.69917s infinite; }

@keyframes drop-138 {
  0% {
    top: 80px;
    left: 66%; } }
  .SwapCompleted .confetti-139 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 19%;
    opacity: 0.34567;
    transform: rotate(64.02433deg);
    animation: drop-139 4.11383s 0.03132s infinite; }

@keyframes drop-139 {
  0% {
    top: 80px;
    left: 23%; } }
  .SwapCompleted .confetti-140 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 42%;
    opacity: 0.51322;
    transform: rotate(64.99799deg);
    animation: drop-140 4.70506s 0.52454s infinite; }

@keyframes drop-140 {
  0% {
    top: 80px;
    left: 44%; } }
  .SwapCompleted .confetti-141 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 14%;
    opacity: 0.85756;
    transform: rotate(8.12738deg);
    animation: drop-141 4.06955s 0.43699s infinite; }

@keyframes drop-141 {
  0% {
    top: 80px;
    left: 15%; } }
  .SwapCompleted .confetti-142 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 6%;
    opacity: 0.37178;
    transform: rotate(298.63797deg);
    animation: drop-142 4.04194s 0.62599s infinite; }

@keyframes drop-142 {
  0% {
    top: 80px;
    left: 7%; } }
  .SwapCompleted .confetti-143 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 21%;
    opacity: 0.75236;
    transform: rotate(222.74888deg);
    animation: drop-143 4.01638s 0.42564s infinite; }

@keyframes drop-143 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-144 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 79%;
    opacity: 0.94913;
    transform: rotate(105.06308deg);
    animation: drop-144 4.14505s 0.36688s infinite; }

@keyframes drop-144 {
  0% {
    top: 80px;
    left: 81%; } }
  .SwapCompleted .confetti-145 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 83%;
    opacity: 0.73718;
    transform: rotate(59.69189deg);
    animation: drop-145 4.36126s 0.19396s infinite; }

@keyframes drop-145 {
  0% {
    top: 80px;
    left: 86%; } }
  .SwapCompleted .confetti-146 {
    width: 6px;
    height: 2.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 70%;
    opacity: 0.9609;
    transform: rotate(273.59419deg);
    animation: drop-146 4.48657s 0.20505s infinite; }

@keyframes drop-146 {
  0% {
    top: 80px;
    left: 71%; } }
  .SwapCompleted .confetti-147 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 19%;
    opacity: 0.44648;
    transform: rotate(84.60659deg);
    animation: drop-147 4.73229s 0.12287s infinite; }

@keyframes drop-147 {
  0% {
    top: 80px;
    left: 23%; } }
  .SwapCompleted .confetti-148 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 43%;
    opacity: 0.37548;
    transform: rotate(221.08383deg);
    animation: drop-148 4.59093s 0.72302s infinite; }

@keyframes drop-148 {
  0% {
    top: 80px;
    left: 47%; } }
  .SwapCompleted .confetti-149 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 31%;
    opacity: 0.86501;
    transform: rotate(255.545deg);
    animation: drop-149 4.97946s 0.33665s infinite; }

@keyframes drop-149 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-150 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 84%;
    opacity: 0.96174;
    transform: rotate(233.78097deg);
    animation: drop-150 4.21111s 0.48218s infinite; }

@keyframes drop-150 {
  0% {
    top: 80px;
    left: 86%; } }
