.TopDetails {
  z-index: 3 !important;
  width: 100%;
  padding: 0 !important;
  margin: 0; }
  .TopDetails_timeLeft {
    text-align: center;
    padding-top: 32.5px;
    color: white; }
  .TopDetails_progress {
    background: #D8E2F0;
    height: 25px; }
    .TopDetails_progress_fill {
      background: #1CE5C3;
      height: 25px; }
