.WalletPanel {
  background: #F7F8F9;
  padding: 40px 0;
  height: 240px; }
  .WalletPanel_left {
    margin-left: 125px; }
  .WalletPanel_right {
    margin-right: 125px; }
    .WalletPanel_right .WalletDisplay {
      float: right; }
  .WalletPanel_walletImg {
    width: 40px; }
