.WalletConnected {
  text-align: center;
  font-family: "Montserrat", sans-serif; }
  .WalletConnected h2 {
    padding: 10px;
    margin-top: 10px;
    line-height: 2rem;
    color: #1D1E21; }
  .WalletConnected_divider {
    border-bottom: 2px solid #F7F8F9;
    width: 100%;
    margin-bottom: 5%; }
  .WalletConnected_subtitle {
    font-size: 20px;
    color: #747E8D; }
  .WalletConnected_currencyBar {
    width: 150px;
    font-family: "Montserrat", sans-serif;
    height: 21px;
    margin: 0 auto;
    color: #ffffff;
    background: #7b87e0;
    background: linear-gradient(90deg, #7b87e0 0%, #71b1d2 100%);
    border-radius: 25px;
    margin-top: -7px; }
  .WalletConnected_wallet {
    border-radius: 10px;
    padding: 40px 10px;
    margin: 10px 5px 20px; }
    .WalletConnected_wallet h5 {
      padding-top: 7px;
      color: #333;
      font-family: 'Montserrat', sans-serif; }
    .WalletConnected_wallet p {
      font-family: "Montserrat", sans-serif;
      color: #747E8D; }
