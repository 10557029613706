.WalletChoose {
  text-align: center;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif; }
  .WalletChoose_divider {
    border-bottom: 3px solid #F7F8F9;
    margin-bottom: 5%; }
  .WalletChoose_currencyBar {
    width: 150px;
    font-family: "Montserrat", sans-serif;
    height: 21px;
    margin: 0 auto;
    color: #ffffff;
    background: #7b87e0;
    background: linear-gradient(90deg, #7b87e0 0%, #71b1d2 100%);
    border-radius: 25px;
    margin-top: -10px; }
  .WalletChoose h1 {
    color: #1D1E21;
    font-weight: 300;
    line-height: 1.6;
    color: #747E8D;
    text-align: center;
    font-size: 1.7rem;
    margin-bottom: 20px; }
  .WalletChoose_WalletsContainer {
    margin: 10px 5px 20px; }
  .WalletChoose_wallet {
    border-radius: 10px;
    background: #F7F8F9;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1%; }
    .WalletChoose_wallet:hover {
      background-color: #f1f0f9; }
    .WalletChoose_wallet h5 {
      margin-bottom: 0; }
    .WalletChoose_wallet_image {
      margin-right: 15px;
      width: 20px; }
