.WalletConnectPopup {
  margin-top: -200px;
  margin-left: -250px;
  font-family: sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  min-height: 300px;
  background-color: white;
  border-radius: 5%;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 9px;
  -moz-background-size: 100% 9px;
  background-size: 100% 9px;
  background-image: webkit-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: -moz-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: -o-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: linear-gradient(to right, #8f60ee 0%, #719cd8 51%, #6fdec6 100%); }
