.SwapInitiation_topBar {
  display: inline-block;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 200px;
  background: white;
  border: 2px solid black;
  font-family: "Montserrat", sans-serif; }

.SwapInitiation_logo {
  display: flex;
  justify-content: center; }

.SwapInitiation_buttonBottom {
  border-radius: 50px; }

.SwapInitiation_liqualityCircle {
  position: absolute;
  top: 192.5px;
  left: 375px;
  z-index: 3;
  display: block;
  width: 150px;
  height: 150px;
  margin-bottom: 2px;
  border-radius: 50%;
  padding: 30px 10px;
  background: #FFFFFF;
  border: 1px solid #D9DFE5; }
  .SwapInitiation_liqualityCircle h3 {
    font-size: 12px;
    text-align: center;
    margin-top: 3px; }

.SwapInitiation_assetSelector {
  padding-top: 20px;
  box-shadow: 0px 2px 14px rgba(69, 73, 91, 0.04);
  border-radius: 0 0 22px 22px;
  background: #FFFFFF;
  width: 100%;
  z-index: 3; }

.SwapInitiation_top {
  width: 100%;
  padding-bottom: 75px;
  border-radius: 0 0 22px 22px;
  z-index: 3; }

.SwapInitiation_quoteTimer {
  padding: 30px; }

.SwapInitiation_bottom {
  position: relative;
  text-align: center;
  bottom: 50px;
  padding: 1px 30px 20px;
  background: #FFFFFF;
  z-index: 3; }

.SwapInitiation_counterPartyLabel {
  position: relative;
  background: #FFFFFF;
  top: -20px;
  display: inline-block;
  margin: 0 auto;
  padding: 12px 16px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 22px; }

.SwapInitiation_handshake {
  width: 70px;
  height: 70px;
  display: inline-block;
  position: relative;
  background: #FFFFFF;
  top: -35px;
  right: -35px;
  left: -35px;
  border-radius: 50%;
  margin-bottom: -30px; }
  .SwapInitiation_handshake img {
    width: 38px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

.SwapInitiation_errorMessage {
  height: 0.75rem;
  color: #F12274;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 2px 0;
  box-sizing: content-box;
  text-align: center; }

.SwapInitiation .TransactionDetails {
  margin-bottom: 30px; }

.SwapInitiation .InitiatorExpirationInfo {
  margin: 20px 0 30px 0; }

.SwapInitiation .WalletPanel {
  position: relative;
  top: -22px;
  z-index: 2; }
