.Rate {
  position: relative;
  display: block;
  text-align: center;
  width: 250px; }
  .Rate_circle {
    margin: 0 auto;
    display: block;
    width: 150px;
    height: 150px;
    margin-bottom: 2px;
    border-radius: 50%;
    padding: 30px 10px;
    background: #FFFFFF; }
  .Rate.error .Rate_circle {
    border: 1px solid #F12274; }
  .Rate input::-webkit-outer-spin-button,
  .Rate input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .Rate input[type='number'] {
    -moz-appearance: textfield; }
  .Rate .Rate_stable {
    font-weight: bold;
    color: #1D1E21; }
  .Rate.strong .Rate_equal, .Rate.strong .Rate_amount {
    font-weight: bold;
    color: #1D1E21; }
  .Rate_heading {
    display: block;
    text-align: center;
    margin-bottom: 6px; }
  .Rate_input {
    border: 0;
    outline: 0;
    background: none;
    width: 55px;
    height: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid #c7c7c7; }
  .Rate_equal {
    margin-bottom: 0px; }
  .Rate_stable {
    margin-bottom: 0px; }
  .Rate_errorMessage {
    height: 0.75rem;
    color: #F12274;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 2px 0;
    box-sizing: content-box;
    text-align: center; }
  .Rate_timer {
    width: 160px;
    height: 160px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto; }
    .Rate_timer_progress {
      fill: none;
      stroke: #1CE5C3;
      stroke-width: 2px;
      transition: stroke-dashoffset 0.7s;
      stroke-dasharray: 628.319;
      stroke-dashoffset: -1256.64; }
    .Rate_timer_pointer {
      transform: rotate(0deg);
      animation-iteration-count: 1; }
      .Rate_timer_pointer_c {
        fill: #FFF;
        stroke: #1CE5C3;
        stroke-width: 2px; }

@keyframes countdown {
  from {
    stroke-dashoffset: -1256.64; }
  to {
    stroke-dashoffset: -628.319; } }

@keyframes pointer {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }
