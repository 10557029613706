.BrandCard {
  padding: 7px 0 0 0;
  background: #FFFFFF;
  box-shadow: 0px -2px 14px rgba(69, 73, 91, 0.04);
  border-radius: 22px;
  margin-bottom: 20px; }
  .BrandCard_whiteBar {
    position: absolute;
    width: 250px;
    height: 17px;
    top: 0%;
    left: 500px;
    border-radius: 25px;
    background: #FFFFFF; }
    .BrandCard_whiteBar p {
      font-size: 11px;
      text-align: center; }
  .BrandCard_top {
    padding: 0 7px 0 7px;
    z-index: 3; }
  .BrandCard_title {
    display: block;
    height: 75px;
    border-radius: 22px 22px 0 0;
    line-height: 2.5em;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
    background: #9D4DFA; }
