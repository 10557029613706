.CurrencyInput {
  width: 320px; }
  .CurrencyInput input::-webkit-outer-spin-button,
  .CurrencyInput input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .CurrencyInput input[type='number'] {
    -moz-appearance: textfield; }
  .CurrencyInput_inputBar {
    border-bottom: 1px solid #1CE5C3;
    width: 220px;
    margin-left: 102px; }
  .CurrencyInput_inputWrapper {
    width: 100%; }
    .CurrencyInput_inputWrapper.disabled {
      border: none; }
  .CurrencyInput_dropdownWrap {
    position: absolute;
    z-index: 3; }
    .CurrencyInput_dropdownWrap h2 {
      color: #1D1E21;
      display: inline; }
      .CurrencyInput_dropdownWrap h2 :hover {
        cursor: pointer; }
    .CurrencyInput_dropdownWrap_toggler {
      display: inline-block; }
  .CurrencyInput_dropdownCurrencyText {
    margin-left: 12px; }
  .CurrencyInput_dropdownCode {
    position: relative;
    top: 5px; }
  .CurrencyInput_dropItem {
    height: 32px;
    width: 32px; }
  .CurrencyInput_drop:hover {
    cursor: pointer; }
  .CurrencyInput_dropdownIcon {
    height: 18px;
    width: 18px;
    margin-left: 5px; }
  .CurrencyInput_dropdownKey {
    border-bottom: 1px solid #D9DFE5;
    display: flex;
    flex-flow: row nowrap;
    justify-content: left; }
    .CurrencyInput_dropdownKey:hover {
      background-color: #F0F7F9; }
  .CurrencyInput_dropMenu {
    display: flex;
    flex-flow: column nowrap;
    background-color: #FFFFFF;
    margin-top: 3px;
    margin-left: 37px;
    border: 1px solid #D9DFE5;
    border-bottom: none;
    width: 100px; }
  .CurrencyInput_listItem {
    display: flex;
    padding-left: 10px;
    justify-content: center; }
  .CurrencyInput_input {
    border: 0;
    outline: 0;
    background: none;
    text-align: right;
    font-size: 24px;
    font-weight: 500;
    width: 320px; }
    .CurrencyInput_input.error {
      border: 1px solid #F12274; }
  .CurrencyInput_price {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .CurrencyInput_labelSwap {
    color: #3D4767;
    font-weight: bold;
    font-size: 14px; }
  .CurrencyInput_priceFiat {
    text-align: right;
    font-size: 0.75rem;
    color: #747E8D;
    padding-top: 3px; }
  .CurrencyInput_label {
    height: 20px;
    padding-top: 1px;
    font-size: 0.75rem;
    text-align: right;
    color: #747E8D; }
  .CurrencyInput_errorMessage {
    height: 0.75rem;
    color: #F12274;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 2px 0;
    box-sizing: content-box;
    text-align: center; }
