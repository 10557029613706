.WalletConnecting {
  padding: 15px;
  text-align: center; }
  .WalletConnecting_wallet {
    text-align: left;
    border-radius: 16px;
    background-color: #F7F8F9;
    padding: 10px 16px; }
  .WalletConnecting_imgContainer {
    display: inline-block;
    width: 70px; }
  .WalletConnecting_header {
    display: flex; }
    .WalletConnecting_header img {
      width: 35px; }
    .WalletConnecting_header h3 {
      color: #1D1E21;
      width: 100%;
      margin: 0;
      padding-top: 6px; }
  .WalletConnecting_error {
    display: flex;
    padding-top: 10px; }
    .WalletConnecting_error img {
      height: 25px;
      margin-left: 14px;
      margin-top: 8px; }
    .WalletConnecting_error_message {
      width: 100%;
      color: #F12274;
      margin: 0; }
  .WalletConnecting h2 {
    font-size: 30px;
    color: #1D1E21;
    padding: 16px 10px;
    margin: 0; }
  .WalletConnecting > p {
    padding: 0 10px 30px 10px; }
