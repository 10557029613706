.WalletActionPopup {
  margin-top: -200px;
  margin-left: -250px;
  font-family: sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  min-height: 300px;
  background-color: white;
  border-radius: 5%;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 9px;
  -moz-background-size: 100% 9px;
  background-size: 100% 9px;
  background-image: webkit-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: -moz-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: -o-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: linear-gradient(to right, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  padding: 0 50px 50px 50px; }
  .WalletActionPopup h1 {
    margin: 0;
    color: #1D1E21;
    padding-top: 54px; }
  .WalletActionPopup_type {
    display: block;
    width: 110px;
    height: 22px;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    font-size: 0.75rem;
    line-height: 22px;
    color: #f2f2f2;
    background-size: 100% 100%;
    background-image: webkit-linear-gradient(left, #7186E5 0%, #58A3DB 100%);
    background-image: -moz-linear-gradient(left, #7186E5 0%, #58A3DB 100%);
    background-image: -o-linear-gradient(left, #7186E5 0%, #58A3DB 100%);
    background-image: linear-gradient(to right, #7186E5 0%, #58A3DB 100%); }
  .WalletActionPopup_stepper .WalletActionPopup_stepper_label {
    color: #747E8D; }
    .WalletActionPopup_stepper .WalletActionPopup_stepper_label .WalletActionPopup_stepper_label_active {
      color: #747E8D;
      font-weight: bold; }
    .WalletActionPopup_stepper .WalletActionPopup_stepper_label .WalletActionPopup_stepper_label_completed {
      color: #747E8D; }
    .WalletActionPopup_stepper .WalletActionPopup_stepper_label .WalletActionPopup_stepper_icon {
      width: 30px;
      height: 30px;
      border: 1px solid #9D4DFA;
      color: #ffffff;
      border-radius: 50%; }
      .WalletActionPopup_stepper .WalletActionPopup_stepper_label .WalletActionPopup_stepper_icon .WalletActionPopup_stepper_icon_text {
        fill: #9D4DFA; }
    .WalletActionPopup_stepper .WalletActionPopup_stepper_label .WalletActionPopup_stepper_icon_active {
      color: #9D4DFA;
      border: 0; }
      .WalletActionPopup_stepper .WalletActionPopup_stepper_label .WalletActionPopup_stepper_icon_active .WalletActionPopup_stepper_icon_text {
        fill: #ffffff; }
  .WalletActionPopup_stepper_root {
    padding: 20px 0 !important; }
  .WalletActionPopup_stepper_label {
    margin-right: 30px; }
  .WalletActionPopup_step {
    padding: 20px 0;
    font-weight: bold; }
  .WalletActionPopup_instructions {
    background: #F7F8F9;
    padding: 10px 0;
    margin-top: 20px;
    border-radius: 16px;
    font-size: 0.75rem; }
    .WalletActionPopup_instructions p {
      margin-bottom: 0; }
    .WalletActionPopup_instructions_description {
      padding: 0 20px 0 32px; }
    .WalletActionPopup_instructions_info {
      padding: 4px 20px 0 32px;
      font-size: 11px; }
      .WalletActionPopup_instructions_info img {
        width: 13px;
        margin-top: -2px; }
    .WalletActionPopup_instructions_image {
      display: block;
      margin: 20px auto; }
