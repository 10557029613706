.AddressInput_wrapper {
  display: flex; }

.AddressInput_box {
  flex: 1;
  height: 45px;
  padding: 8px 10px;
  background: #F7F8F9;
  border-radius: 25px;
  display: flex; }

.AddressInput.error .AddressInput_box {
  border: 1px solid #F12274; }

.AddressInput_icon {
  flex: 0;
  height: 29px;
  width: auto;
  justify-content: center;
  margin-right: 8px; }

.AddressInput_input {
  border: 0;
  outline: 0;
  background: none;
  color: #747E8D;
  font-size: 13px;
  flex: 1; }

.AddressInput_status {
  flex: 0;
  margin-left: 8px; }

.AddressInput_errorMessage {
  padding: 3px 0 0 50px;
  font-size: 0.75rem;
  color: #F12274; }
