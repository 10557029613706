.TransactionDetails {
  text-align: left;
  display: flex;
  padding: 14px 30px;
  padding-bottom: 0;
  height: 100px;
  margin: 0 auto;
  background: #F1F4F7;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px; }
  .TransactionDetails_passed, .TransactionDetails_left {
    display: flex;
    align-items: center; }
  .TransactionDetails_passed {
    margin-right: 20px; }
  .TransactionDetails_left {
    margin-left: 20px; }
  .TransactionDetails_clock {
    width: 25px;
    margin-right: 20px; }
  .TransactionDetails_center {
    flex: 1;
    display: flex;
    flex-direction: column; }
  .TransactionDetails_top {
    display: flex;
    justify-content: space-between; }
  .TransactionDetails_bottom {
    display: flex;
    justify-content: space-between;
    background: #F1F4F7; }
  .TransactionDetails_terms {
    font-weight: bold; }
    .TransactionDetails_terms em {
      font-style: normal;
      color: #9D4DFA; }
  .TransactionDetails_link {
    cursor: pointer;
    padding-top: 10px; }
    .TransactionDetails_link img {
      margin-left: 10px; }
  .TransactionDetails_transactions {
    width: 100%;
    font-size: 11px; }
  .TransactionDetails_transaction {
    float: left;
    width: 50%; }
    .TransactionDetails_transaction:nth-child(2) {
      text-align: right; }
    .TransactionDetails_transaction_name {
      padding-right: 10px; }
    .TransactionDetails_transaction_link {
      padding-right: 10px; }
    .TransactionDetails_transaction_confirmations {
      color: #747E8D; }
    .TransactionDetails_transaction_missing {
      display: inline-block;
      width: 160px;
      color: #9D4DFA;
      text-align: left; }
  .TransactionDetails_transaction_right {
    display: flex;
    justify-content: flex-end;
    float: right;
    width: 50%; }
    .TransactionDetails_transaction_right:nth-child(2) {
      text-align: right; }
    .TransactionDetails_transaction_right_name {
      padding-left: 25%; }
    .TransactionDetails_transaction_right_link {
      padding-right: 10px; }
    .TransactionDetails_transaction_right_confirmations {
      color: #747E8D; }
    .TransactionDetails_transaction_right_missing {
      display: inline-block;
      width: 160px;
      color: #9D4DFA;
      text-align: left; }
