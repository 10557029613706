.CurrencyInputs {
  position: relative;
  width: 100%; }
  .CurrencyInputs_left {
    margin-left: 40px;
    margin-top: 20px; }
  .CurrencyInputs_right {
    margin-right: 40px;
    padding-right: 40px;
    margin-top: 20px; }
    .CurrencyInputs_right .CurrencyInput {
      float: right; }
  .CurrencyInputs_switch {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: relative;
    top: 56px;
    z-index: 3;
    text-align: center; }
    .CurrencyInputs_switch:hover {
      cursor: pointer; }
  .CurrencyInputs .Rate {
    position: absolute;
    right: 325px;
    top: 95px;
    z-index: 3; }
