.BackupLinkCard {
  text-align: center;
  height: 700px; }
  .BackupLinkCard h3 {
    font-weight: bold;
    color: #1D1E21;
    padding-top: 35px;
    text-align: center; }
    .BackupLinkCard h3 img {
      width: 32px;
      margin-bottom: 10px; }
  .BackupLinkCard_whiteBar {
    margin: 0 auto;
    margin-top: -90px;
    margin-bottom: 50px;
    background-color: white;
    height: 20px;
    width: 300px;
    border-radius: 25px; }
  .BackupLinkCard_whiteHover:hover {
    background: #FFFFFF;
    border: 1px solid #9D4DFA;
    color: #9D4DFA; }
  .BackupLinkCard_description {
    width: 365px;
    text-align: left;
    margin: 0 auto;
    padding-left: 10px; }
  .BackupLinkCard_icon {
    margin-top: 30px;
    width: 40px; }
