.StatusMessage {
  height: 400px;
  text-align: center;
  padding: 7px 20px;
  width: 650px;
  margin: 0 auto;
  z-index: 3;
  font-family: "Montserrat", sans-serif; }
  .StatusMessage_complete {
    background: none;
    padding: 12px 20px;
    display: none; }
  .StatusMessage_circle {
    margin: 0 auto;
    margin-top: 160px;
    margin-left: 5px;
    display: block;
    width: 263px;
    height: 263px;
    margin-bottom: 2px;
    border-radius: 50%;
    padding: 30px 10px;
    z-index: 3;
    background: #FFFFFF; }
  .StatusMessage_wrapper {
    display: inline-flex;
    align-items: flex-start;
    margin: 0 auto; }
  .StatusMessage img {
    display: block;
    float: left;
    width: 30px;
    margin-right: 15px; }
  .StatusMessage_message {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.75;
    padding-top: 26.5%; }
  .StatusMessage_complete .StatusMessage_message {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    padding-top: 0; }
  .StatusMessage_estimate {
    font-size: 0.75rem;
    color: #747E8D;
    padding-top: 2px; }
  .StatusMessage_timer {
    width: 160px;
    height: 160px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto; }
    .StatusMessage_timer_progress {
      fill: none;
      stroke: #1CE5C3;
      stroke-width: 2px;
      transition: stroke-dashoffset 0.7s;
      stroke-dasharray: 628.319;
      stroke-dashoffset: -1256.64; }
    .StatusMessage_timer_pointer {
      transform: rotate(0deg);
      animation-iteration-count: 1; }
      .StatusMessage_timer_pointer_c {
        fill: #FFF;
        stroke: #1CE5C3;
        stroke-width: 2px; }

@keyframes animate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
    .StatusMessage_timer_statusMessage {
      z-index: 3;
      position: absolute;
      top: 111px;
      left: 128px;
      animation: none; }
