.SwapRefunded {
  text-align: center; }
  .SwapRefunded_terms {
    font-size: 30px; }
  .SwapRefunded_button {
    margin-bottom: 10px; }
  .SwapRefunded_expiredFrame {
    text-align: center;
    width: 100%;
    color: #A4AAB6;
    margin-top: -20px;
    border-radius: 0px 0px 22px 22px;
    padding: 50px; }
    .SwapRefunded_expiredFrame_content {
      margin-left: 30%;
      width: 40%;
      text-align: left; }
      .SwapRefunded_expiredFrame_content_title {
        text-align: center;
        color: #494949; }
      .SwapRefunded_expiredFrame_content_value {
        color: #5F5F5F; }
