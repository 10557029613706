.InitiatorExpirationInfo {
  padding-top: 20px; }
  .InitiatorExpirationInfo p {
    display: flex;
    width: 100%;
    margin: 0;
    color: #747E8D; }
  .InitiatorExpirationInfo h5 {
    color: #1D1E21;
    font-weight: bold; }
  .InitiatorExpirationInfo_icon {
    height: 25px;
    margin-right: 10px; }
