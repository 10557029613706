.SwapRedemption {
  text-align: center; }
  .SwapRedemption_bigWrap {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    margin: 0 !important; }
  .SwapRedemption_terms {
    font-size: 20px;
    margin: 50px 0 60px 0; }
  .SwapRedemption_buttonWrap {
    margin-top: 350px;
    width: 100%;
    display: flex;
    justify-content: center; }
  .SwapRedemption_info {
    width: 600px;
    margin: 0 auto 30px auto;
    text-align: left; }
  .SwapRedemption_errorMessage {
    height: 0.75rem;
    color: #F12274;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 2px 0;
    box-sizing: content-box;
    text-align: center; }
