.SwapRefund {
  text-align: center; }
  .SwapRefund_confirmation {
    font-weight: 700;
    position: relative;
    top: 10px; }
  .SwapRefund_terms {
    font-size: 40px; }
  .SwapRefund_pairDiv {
    display: flex;
    flex-flow: row nowrap;
    width: 100%; }
    .SwapRefund_pairDiv p {
      justify-content: flex-start;
      width: 50%; }
    .SwapRefund_pairDiv span {
      justify-content: flex-end;
      width: 50%;
      text-align: right; }
  .SwapRefund_expiredFrame {
    text-align: center;
    width: 100%;
    background-color: #F7F8FA;
    color: #A4AAB6;
    border-radius: 0px 0px 22px 22px;
    padding: 50px; }
    .SwapRefund_expiredFrame_content {
      margin-left: 30%;
      width: 40%;
      text-align: left; }
      .SwapRefund_expiredFrame_content_title {
        text-align: center;
        color: #494949; }
      .SwapRefund_expiredFrame_content_value {
        color: #5F5F5F; }
