.WalletDisplay {
  text-align: center;
  width: 160px; }
  .WalletDisplay_type p {
    padding: 0;
    margin: 0; }
  .WalletDisplay.error > .WalletDisplay_wrapper {
    padding: 10px 0;
    border: 1px solid #F12274; }
  .WalletDisplay_icon {
    height: 20px; }
  .WalletDisplay_emptyAddress {
    position: relative;
    top: 25px; }
  .WalletDisplay_emptyButton {
    position: relative;
    bottom: 100px; }
  .WalletDisplay_address {
    width: 160px;
    height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.75rem;
    color: #747E8D; }
    .WalletDisplay_address:hover {
      overflow: visible; }
    .WalletDisplay_address.error {
      border: 1px solid #F12274;
      padding: 2px 4px; }
  .WalletDisplay_addressButtons {
    background: none; }
    .WalletDisplay_addressButtons:hover {
      background: white;
      color: #9D4DFA;
      border: 1px solid #9D4DFA; }
  .WalletDisplay_addressWhole {
    display: inline-block; }
  .WalletDisplay_walletAddressConnected {
    padding-left: 5px; }
  .WalletDisplay_message {
    font-size: 0.75rem;
    color: #9D4DFA; }
    .WalletDisplay_message.error {
      color: #F12274; }
  .WalletDisplay_balance {
    color: #747E8D; }
    .WalletDisplay_balance.error {
      border: 1px solid #F12274; }
  .WalletDisplay_errorMessage {
    height: 0.75rem;
    color: #F12274;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 2px 0;
    box-sizing: content-box;
    text-align: center; }
