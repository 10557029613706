.SwapProgressStepper {
  padding-bottom: 0 !important;
  font-family: "Montserrat", sans-serif; }
  .SwapProgressStepper .SwapProgressStepper_label {
    color: #747E8D; }
    .SwapProgressStepper .SwapProgressStepper_label .SwapProgressStepper_label_active {
      color: #747E8D;
      font-weight: bold; }
    .SwapProgressStepper .SwapProgressStepper_label .SwapProgressStepper_label_completed {
      color: #747E8D; }
    .SwapProgressStepper .SwapProgressStepper_label .SwapProgressStepper_icon {
      width: 30px;
      height: 30px;
      border: 1px solid #9D4DFA;
      color: #ffffff;
      border-radius: 50%; }
      .SwapProgressStepper .SwapProgressStepper_label .SwapProgressStepper_icon .SwapProgressStepper_icon_text {
        fill: #9D4DFA; }
    .SwapProgressStepper .SwapProgressStepper_label .SwapProgressStepper_icon_active {
      color: #9D4DFA;
      border: 0; }
      .SwapProgressStepper .SwapProgressStepper_label .SwapProgressStepper_icon_active .SwapProgressStepper_icon_text {
        fill: #ffffff; }
  .SwapProgressStepper .SwapProgressStepper_label span {
    font-family: "Montserrat", sans-serif; }
  .SwapProgressStepper .SwapProgressStepper_icon_text {
    font-family: "Montserrat", sans-serif; }
  .SwapProgressStepper .SwapProgressStepper_connector {
    left: calc(-50% + 15px);
    right: calc(50% + 15px); }
    .SwapProgressStepper .SwapProgressStepper_connector_line {
      border: none;
      padding-top: 4px;
      height: 1px;
      background-image: linear-gradient(to right, #D8D8D8 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 25px 1px;
      background-repeat: repeat-x; }
    .SwapProgressStepper .SwapProgressStepper_connector_label {
      font-family: "Montserrat", sans-serif; }
    .SwapProgressStepper .SwapProgressStepper_connector_completed .SwapProgressStepper_connector_line, .SwapProgressStepper .SwapProgressStepper_connector_active .SwapProgressStepper_connector_line {
      height: 3px;
      border: none;
      background: #1CE5C3; }
