.ErrorModal {
  max-width: 400px;
  margin-left: -200px;
  font-family: sans-serif;
  position: fixed;
  z-index: 5000;
  top: 40px;
  right: 40px;
  color: #F12274;
  background-color: white;
  box-shadow: 0px -2px 14px rgba(69, 73, 91, 0.3);
  border-radius: 10px;
  padding: 34px 40px 10px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 9px;
  -moz-background-size: 100% 9px;
  background-size: 100% 9px;
  background-image: webkit-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: -moz-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: -o-linear-gradient(left, #8f60ee 0%, #719cd8 51%, #6fdec6 100%);
  background-image: linear-gradient(to right, #8f60ee 0%, #719cd8 51%, #6fdec6 100%); }
  .ErrorModal_close {
    position: absolute;
    width: 20px;
    top: 15px;
    right: 5px; }
