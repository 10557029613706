.Waiting {
  text-align: center; }
  .Waiting_whiteBar {
    margin: 0 auto;
    margin-top: -10%;
    background-color: white;
    height: 20px;
    width: 300px;
    border-radius: 25px; }

@keyframes animate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .Waiting_spinningGradient {
    position: absolute;
    left: 315px;
    top: 181px;
    width: 275px;
    height: 275px;
    border-radius: 50%;
    background: linear-gradient(#9D4DFA, #1CE5C3, #00a2ff);
    animation: animate 1.5s infinite ease-in-out; }
    .Waiting_spinningGradient span {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%; }
      .Waiting_spinningGradient span span:nth-child(1) {
        filter: blur(5px); }
      .Waiting_spinningGradient span span:nth-child(2) {
        filter: blur(10px); }
      .Waiting_spinningGradient span span:nth-child(3) {
        filter: blur(25px); }
      .Waiting_spinningGradient span span:nth-child(4) {
        filter: blur(50px); }
  .Waiting_spinningGradient:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #f1f1f1;
    border: solid white 10px;
    border-radius: 50%; }
  .Waiting_statusMessage {
    animation: none;
    position: absolute; }
  .Waiting_quoteTimer {
    padding: 14px; }
  .Waiting_status {
    margin: 30px 30px 80px 30px;
    font-size: 18px; }
