.Button {
  font-weight: 600;
  position: relative; }
  .Button_whiteButton:hover {
    background: white;
    border: 1px solid #9D4DFA;
    color: #9D4DFA; }
  .Button_wide {
    width: 350px; }
  .Button_small {
    padding: 6px 10px;
    font-size: 0.75rem;
    font-weight: 600; }
  .Button_icon {
    position: absolute;
    width: 54px;
    left: 0;
    border-right: 1px solid #C49FEF;
    text-align: center; }
    .Button_icon img {
      max-height: 20px; }
  .Button_spinner {
    margin: -12px 10px -10px 0;
    width: 26px; }

.btn-primary {
  color: #ffffff; }

.btn-secondary {
  background: #ffffff;
  border: 1px solid #9D4DFA;
  color: #9D4DFA; }
  .btn-secondary .Button_icon {
    border-color: #9D4DFA; }
